.tex:hover{
    color: green;
    transition: 2;
}

.headers {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

#test {
  background: linear-gradient(green, green) left no-repeat, black;
  background-size: 0% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 2s ease-in-out;
}

#test:hover {
  background-size: 100% 100%;
}

