.contain svg{
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.contain {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 10%;
  vertical-align: middle;
  overflow: hidden;
  background-color: #f9f1e1;
  
}